exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artifacter-tsx": () => import("./../../../src/pages/artifacter.tsx" /* webpackChunkName: "component---src-pages-artifacter-tsx" */),
  "component---src-pages-atk-crit-tsx": () => import("./../../../src/pages/atk-crit.tsx" /* webpackChunkName: "component---src-pages-atk-crit-tsx" */),
  "component---src-pages-avg-dmg-tsx": () => import("./../../../src/pages/avg-dmg.tsx" /* webpackChunkName: "component---src-pages-avg-dmg-tsx" */),
  "component---src-pages-beta-access-tsx": () => import("./../../../src/pages/beta-access.tsx" /* webpackChunkName: "component---src-pages-beta-access-tsx" */),
  "component---src-pages-developer-tools-tsx": () => import("./../../../src/pages/developer-tools.tsx" /* webpackChunkName: "component---src-pages-developer-tools-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-player-card-fukafukafuka-29-tsx": () => import("./../../../src/pages/player-card-fukafukafuka29.tsx" /* webpackChunkName: "component---src-pages-player-card-fukafukafuka-29-tsx" */),
  "component---src-pages-player-card-templates-tsx": () => import("./../../../src/pages/player-card-templates.tsx" /* webpackChunkName: "component---src-pages-player-card-templates-tsx" */),
  "component---src-pages-player-card-tsx": () => import("./../../../src/pages/player-card.tsx" /* webpackChunkName: "component---src-pages-player-card-tsx" */),
  "component---src-pages-showcase-analytics-tsx": () => import("./../../../src/pages/showcase-analytics.tsx" /* webpackChunkName: "component---src-pages-showcase-analytics-tsx" */),
  "component---src-pages-showcase-tsx": () => import("./../../../src/pages/showcase.tsx" /* webpackChunkName: "component---src-pages-showcase-tsx" */),
  "component---src-pages-talent-materials-tsx": () => import("./../../../src/pages/talent-materials.tsx" /* webpackChunkName: "component---src-pages-talent-materials-tsx" */),
  "component---src-pages-team-builder-tsx": () => import("./../../../src/pages/team-builder.tsx" /* webpackChunkName: "component---src-pages-team-builder-tsx" */),
  "component---src-pages-team-randomizer-tsx": () => import("./../../../src/pages/team-randomizer.tsx" /* webpackChunkName: "component---src-pages-team-randomizer-tsx" */),
  "component---src-pages-wish-banner-tsx": () => import("./../../../src/pages/wish-banner.tsx" /* webpackChunkName: "component---src-pages-wish-banner-tsx" */)
}

