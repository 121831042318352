import * as React from "react"
import type { GatsbyBrowser } from "gatsby"

import { RootInitialLoad } from "./src/utils/create-context"

export const registerServiceWorker: GatsbyBrowser["registerServiceWorker"] = () => true

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <RootElement>
      {element}
    </RootElement>
  )
}
const RootElement: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [initialLoad, setInitialLoad] = React.useState(true)
  React.useEffect(()=>{
    if(initialLoad) setInitialLoad(false)
  },[])
  return (
    <RootInitialLoad.Provider value={initialLoad}>
      {children}
    </RootInitialLoad.Provider>
  )
}
