import { createTheme } from "@mui/material/styles"
import { darkScrollbar } from "@mui/material"

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme)=>({
        html: {
          fontSize: `16px`,
          "@media (min-width:1024px)": { // manual resize
            fontSize: `18px`,
          },
        },
        body: theme.palette.mode === 'dark' ? darkScrollbar() : null,
        h1: {
          fontSize: `1rem`,
          fontWeight: `normal`,
          textAlign: `center`,
        },
        h2: {
          fontSize: `1.6rem`,
          margin: `0 0 1.5rem 0`,
        },
        p: {
          margin: `1rem 0`,
        },
        a: {
          color: theme.palette.link,
          textDecoration: `none`,
        },
        img: {
          width: `100%`,
          height: `auto`,
          verticalAlign: `top`,
        },
        svg: {
          display: `block`,
          fill: `currentColor`,
        },
        canvas: {
          display: `block`,
          maxWidth: `100%`,
        },
        label: {
          cursor: `pointer`,
        },
        ul: {
          marginBlockStart: `1em`,
          marginBlockEnd: `1em`,
          paddingInlineStart: `1.5em`,
        },
        ol: {
          marginBlockStart: `1em`,
          marginBlockEnd: `1em`,
          paddingInlineStart: `1.5em`,
        },
        li: {
          ":not(:last-of-type)": {
            marginBottom: `.75em`,
          },
        },
      }),
    },
    MuiSpeedDial: {
      styleOverrides: {
        root: {
          "& .MuiSpeedDial-actions": {
            margin: `-1rem -1rem -3rem -1rem`,
            padding: `1rem 1rem 3rem 1rem`,
          },
        },
        fab: {
          width: `3.5rem`,
          height: `3.5rem`,
          border: `solid .25rem`,
          borderColor: `#0c0f1d`, // theme.palette.background.default
          margin: `.4rem 0`,
          boxShadow: `none`,
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          width: `3rem`,
          height: `3rem`,
          border: `solid .15rem`,
          borderColor: `#657ef8`, // theme.palette.primary.main
          margin: `.4rem 0`,
          boxShadow: `none`,
        },
      },
    },
    MuiSpeedDialIcon: {
      styleOverrides: {
        root: {
          height: `2.5em`,
        },
        icon: {
          fontSize: `2.5em`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: `none`,
          backgroundImage: `unset`,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: `.5rem 2rem`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: `secondary`,
        variant: `contained`,
      },
      styleOverrides: {
        root: {
          fontWeight: `normal`,
          whiteSpace: `nowrap`,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: `rgba(255, 255, 255, 0.85)`, // theme.palette.text.primary
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
      },
      styleOverrides: {
        root: {
          boxShadow: `none`,
          "::before": {
            display: `none`,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: `.5rem 1rem`,
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `.5rem 1rem`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: `4px`,
          borderRadius: `2px`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: `unset`,
          "&.Mui-selected": {
            color: `rgba(255, 255, 255, 0.85)`, // theme.palette.text.primary
            background: `#1b1d2a`, // theme.palette.background.paper
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: `#484A54`, // theme.palette.background.paper
        },
        arrow: {
          color: `#484A54`, // theme.palette.background.paper
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 1024,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: {
    mode: `dark`,
    background: {
      default: `#0c0f1d`,
      paper: `#1b1d2a`,
    },
    text: {
      primary: `rgba(255, 255, 255, 0.85)`,
      secondary: `rgba(255, 255, 255, 0.45)`,
    },
    primary: {
      main: `#657ef8`,
      light: `rgb(131, 151, 249)`,
      dark: `rgb(70, 88, 173)`,
      contrastText: `#fff`,
    },
    secondary: {
      main: `rgb(70, 88, 173)`,
      light: `rgb(107, 121, 189)`,
      dark: `rgb(49, 61, 121)`,
      contrastText: `#fff`,
    },
    divider: `#343746`,
    link: `#58a6ff`,
  },
})
export default theme

declare module "@mui/material/styles" {
  interface Palette {
    link: string
  }
  interface PaletteOptions {
    link?: string
  }
}
